

<template>
  <!-- [L팝업]마케팅 발송 확인 -->
  <div class="modal-popup modal-popup--show modal-popup--small" id="modalBmSendConfirm">
    <div class="modal-popup__wrap">
      <div class="modal-popup__head">
        <h1 class="modal-popup__title">바이어 상세정보 조회 안내</h1>
      </div>
      <div class="modal-popup__body">
        <div class="modal-popup__content">
          <div class="com-bubble-box">
            <!-- 차감캐시 ≤ 캐시잔액  -->
            <p class="com-bubble-box__msg" v-if="hasEnoughCash == true">대상 바이어와 차감되는 캐시를 확인해 주세요.</p>

            <!-- 차감캐시 ＞ 캐시잔액 -->
            <p class="com-bubble-box__msg" v-if="hasEnoughCash == false">캐시 잔액이 부족합니다. 충전 후 이용해 주세요.</p>
          </div>

          <div class="com-list-info">
            <dl>
              <!-- <dt>발신자</dt> -->
              <!-- <dd>{{sender.name}}({{sender.email}})</dd> -->
            </dl>
            <dl>
              <dt>대상 바이어</dt>
              <dd v-html="buyer.cmpNm"></dd>
            </dl>
            <dl>
              <dt>차감 캐시</dt>
              <dd>
                <strong>{{payAmount}}</strong>
                캐시 (캐시잔액:{{cashBalance.toLocaleString()}} 캐시)
              </dd>
            </dl>
          </div>
        </div>
      </div>
      <!-- //modal-popup__body -->
      <div class="modal-popup__foot">
        <div class="btn-wrap">
          <button type="button" class="btn-sec btn--large" @click="popupClose">이전으로</button>
          <button
            type="button"
            class="btn-pri btn--large"
            v-if="hasEnoughCash == true"
            @click="goToBuyerDetailPage"
            :disabled="isDisabled == true"
          >바이어 확인</button>
          <button
            type="button"
            class="btn-pri btn--large"
            v-if="hasEnoughCash == false"
            @click="goToChargeCashPage"
          >캐시 충전</button>
        </div>
      </div>
      <div class="modal-popup__close">
        <button type="button" @click="popupClose">
          <span class="blind">팝업닫기</span>
        </button>
      </div>
    </div>
    <ModalAlert ref="ModalAlert" />
    <ModalConfirm ref="ModalConfirm" />
    <LoadingBar v-if="isLoading" />
  </div>
  <!-- //[L팝업]마케팅 발송 확인-->
</template>
<script>
import ModalAlert from "@/components/common/ModalAlert.vue";
import ModalConfirm from "@/components/common/ModalConfirm.vue";
import LoadingBar from "@/components/common/LoadingBar.vue";
import { getCashUserBalance } from "@/api/cash";

export default {
  components: {
    ModalAlert,
    ModalConfirm,
    LoadingBar,
  },
  props: {
    isBuyerConfirmPopupShow: Boolean,
    exvtNo: String,
    sender: Object,
    subject: String,
    buyer: Object,
    files: Array,
    forwardFiles: Array,
    content: String,
    isReply: String,
    mktSndNo: String,
    payAmount: Number,
    isFromFavorBuyer: Boolean,
  },
  data: () => ({
    cashBalance: 0,
    //payAmount: 0,
    buyerCnt: 0,
    hasEnoughCash: true,
    isLoading: false,
    isDisabled: false,
  }),
  methods: {
    popupClose() {
      this.$emit("isBuyerConfirmPopupShow", false);
    },
    goToChargeCashPage() {
      this.$emit("goToChargeCashPage");
    },
    async calculateCash() {
      const { data } = await getCashUserBalance();
      this.cashBalance =
        data.data.cash + data.data.freeCash + data.data.plusCash;
      this.hasEnoughCash = this.cashBalance >= this.payAmount;
    },

    goToBuyerDetailPage() {
      //this.isLoading = true;
      this.isDisabled = true;

      if (this.isFromFavorBuyer) {
        // 관심바이어 팝업창으로 들어오는 경우는 팝업창을 닫는 액션이 필요하기 때문에 emmit을 사용.
        this.$emit("closeFavorBuyerPopup");
      }
      this.$router.push({
        name: "buyerDetail",
        params: { dunsNo: this.buyer.dunsNo },
      });
    },
  },
  mounted() {
    this.calculateCash();
  },
};
</script>