import { postSearchBuyer, postSearchReport, postSearchNotice, postSearchHistory, postDeleteSearchHistory } from "@/api/search";

const searchStore = {
    namespaced: true,
    state: {
        searchBuyer : [], // 바이어 리스트
        searchBuyerCount : 0, //총 바이어 갯수

        searchReport : [],  //해외보고서
        searchReportCount: 0,

        searchNotice : [], //고객안내
        searchNoticeCount : 0,

        historys : [],
    },
    getters: {

    },
    mutations: {
        SET_BUYERS(state, searchBuyer){
            state.searchBuyer = searchBuyer;
        },
        SET_BUYERS_COUNT(state, count){
            state.searchBuyerCount = count;
        },
        SET_REPORTS(state, searchReport){
            state.searchReport = searchReport;
        },
        SET_REPORT_COUNT(state, param){
            state.searchReportCount = param;
        },
        SET_NOTICE(state, searchNotice){
            state.searchNotice = searchNotice;
        },
        SET_NOTICE_COUNT(state, param){
            state.searchNoticeCount = param;
        },
        SET_BUYER_PUSH(state, param){
            param.forEach(element => {
                state.searchBuyer.push(element);    
            });                     
        },        
        SET_REPORT_PUSH(state, param){
            param.forEach(element => {
                state.searchReport.push(element);    
            });                     
        },    
        SET_NOTICE_PUSH(state, param){
            param.forEach(element => {
                state.searchNotice.push(element);    
            });                     
        },        
        SET_HISTORY_ARRAY(state, param){
            state.historys = param;
        }
    },
    actions: {
        DELETE_COUNT_RESET({ commit }){
            commit("SET_BUYERS_COUNT", 0);
            commit("SET_REPORT_COUNT", 0);
            commit("SET_NOTICE_COUNT", 0);
        },
        DELETE_ARRAY({ commit }){
            commit('SET_BUYERS', []);
            commit('SET_REPORTS', []);
            commit('SET_NOTICE', []);
        },
        async GET_SEARCH_BUYER({ commit }, params) {
            const { data } = await postSearchBuyer(params);
            commit("SET_BUYER_PUSH", data.data.buyers);
            commit("SET_BUYERS_COUNT", data.data.buyerCount);
        },
        async GET_SEARCH_REPORT({ commit }, params) {
            const { data } = await postSearchReport(params);        
            commit("SET_REPORT_PUSH", data.data.reports);
            commit("SET_REPORT_COUNT", data.data.reportCount);
        },
        async GET_SEARCH_NOTICE({ commit }, params) {
            const { data } = await postSearchNotice(params);        
            commit("SET_NOTICE_PUSH", data.data.noticeList);
            commit("SET_NOTICE_COUNT", data.data.noticeCount);
        },
        async GET_SEARCH_HISTORY({ commit }, params) {
            const { data } = await postSearchHistory(params);            
            commit("SET_HISTORY_ARRAY", data.data);
        },
        async GET_SEARCH_DELETE({ commit }, params){
            const { data } = await postDeleteSearchHistory(params);
            commit("SET_HISTORY_ARRAY", data.data);
        }
    }
}

export default searchStore;