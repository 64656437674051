import { instanceWithAuth } from "@/api/index";

function getBuyerDetail(dunsNoData) {
    return instanceWithAuth.get("/buyer/buyer-detail", { params: dunsNoData });
}

function getBuyerFavor(favorData) {
    return instanceWithAuth.get("/buyer/buyer-detail-favor", { params: favorData });
}

function getScamBuyer(param) {
    return instanceWithAuth.get("/buyer/buyer-scam", { params: param });
}

function getEmailBuyer(param) {
    return instanceWithAuth.get("/buyer/buyer-email", { params: param });
}

function getFavorBuyerList(param) {
    return instanceWithAuth.post("/buyer/favor", param);
}

export { getBuyerDetail, getBuyerFavor, getScamBuyer, getEmailBuyer, getFavorBuyerList };
