import { getSenderInfo } from "@/api/bm";

const bmStore = {
    namespaced: true,
    state: {
        sender: {},
        xBuyers: [],
        xFiles: [],
        xForwardFiles: [],
        xSubject: "",
        xContent: "",
        xMktSndNo: null,
        xIsReply: 'N',
    },
    mutations: {
        CLEAR_ALL(state) {
            state.xBuyers = [];
            state.xFiles = [];
            state.xForwardFiles = [];
            state.xSubject = "";
            state.xContent = "";
            state.xMktSndNo = null;
            state.xIsReply = "N";
        },
        SET_SENDER(state, sender) {
            state.sender = sender;
        },
        SET_BUYERS(state, buyers) {
            state.xBuyers = buyers;
        },
        SET_FILES(state, files) {
            state.xFiles = files;
        },
        SET_FORWARD_FILES(state, files) {
            state.xForwardFiles = files;
        },
        SET_SUBJECT(state, subject) {
            state.xSubject = subject;
        },
        SET_CONTENT(state, content) {
            state.xContent = content;
        },
        SET_MKT_SND_NO(state, mktSndNo) {
            state.mktSndNo = mktSndNo;
        },
        SET_IS_REPLY(state, isReply) {
            state.xIsReply = isReply;
        },
    },
    actions: {
        async GET_SENDER({ commit }) {
            const { data } = await getSenderInfo();
            commit("SET_SENDER", data.data);
        },
        UPDATE_BUYER({ commit }, buyers) {
            commit("SET_BUYERS", buyers);
        },
        UPDATE_FILES({ commit }, files) {
            commit("SET_FILES", files);
        },
        UPDATE_FORWARD_FILES({ commit }, forwardFiles) {
            commit("SET_FORWARD_FILES", forwardFiles);
        },
        UPDATE_SUBJECT({ commit }, subject) {
            commit("SET_SUBJECT", subject);
        },
        UPDATE_CONTENT({ commit }, content) {
            commit("SET_CONTENT", content);
        },
        UPDATE_MKT_SND_NO({ commit }, mktSndNo) {
            commit("SET_MKT_SND_NO", mktSndNo);
        },
        UPDATE_IS_REPLY({ commit }, isReply) {
            commit("SET_IS_REPLY", isReply);
        },
        CLEAR({ commit }) {
            commit("CLEAR_ALL");
        },
    },
};

export default bmStore;