import { getNoticeList, getNoticeByNo } from "@/api/notice";

const noticeStore = {
  namespaced: true,
  state: {
    noticeList: [],
  },
  getters: {
    getNoticeList(state) {
      return state.noticeList;
    },
  },
  mutations: {
    SET_NOTICE_LIST(state, noticeList) {
      state.noticeList = noticeList;
    },
    CLEAR_NOTICE_LIST(state) {
      state.noticeList = [];
    },
  },
  actions: {
    async GET_NOTICE_LIST({ commit }, params) {
      const { data } = await getNoticeList(params);
      commit("SET_NOTICE_LIST", data.data);
    },
    async GET_NOTICE_ONE({ commit }, params) {
      const { data } = await getNoticeByNo(params);
      commit("SET_NOTICE_LIST", data.data);
    },
  },
};

export default noticeStore;
