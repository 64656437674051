<template>
  <div
    class="top_banner"
    @click="goToNotice"
    style="
      background: linear-gradient(45deg, #9bd9ff, #7ce3b9, #baf340);
      height: 55px;
      position: fixed;
      top: 0;
      z-index: 20;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      word-break: keep-all;
    "
  >
    <p
      class="leftTxt"
      style="
        text-align: center;
        background: #ffd913;
        color: #fff;
        padding: 3px 8px;
        border-radius: 25px;
        font-weight: 800;
        margin-right: 50px;
      "
    >
      GRAND OPEN EVENT
    </p>
    <p class="leftTxt_m" style="display: none">
      GRAND OPEN <br />
      EVENT
    </p>
    <div class="rightTxt">
      <p
        style="
          color: #fff;
          font-size: 18px;
          text-align: center;
          font-weight: 600;
          line-height: 1.2;
        "
      >
        신규 가입하면 300,000 캐시 무료 제공 + 선착순 200명 스타벅스 아메리카노
        증정
      </p>
      <p
        style="
          color: #fff;
          text-align: center;
          font-size: 14px;
          padding-top: 3px;
        "
      >
        이벤트 기간 : 2023.12.18 ~ 2024.01.17 (1개월 간)
      </p>
    </div>
    <div class="rightTxt_M" style="display: none">
      <p
        style="
          color: #fff;
          font-size: 18px;
          text-align: center;
          font-weight: 600;
        "
      >
        신규 가입하면 300,000 캐시 무료 제공 <br />
        + 선착순 200명 스타벅스 아메리카노 증정
      </p>
      <p
        style="
          color: #fff;
          text-align: center;
          font-size: 14px;
          padding-top: 3px;
        "
      >
        이벤트 기간 : 2023.12.18 ~ 2024.01.17 (1개월 간)
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: {},
  mounted() {},
  methods: {
    goToNotice() {
      this.$router.push("/cs-notices");
    },
  },
};
</script>
<style>
@media screen and (max-width: 1000px) {
  .top_banner .leftTxt {
    margin-right: 25px !important;
  }
}
@media screen and (max-width: 850px) {
  .top_banner .leftTxt {
    font-size: 12px !important;
  }
  .top_banner .rightTxt p:first-child {
    font-size: 15px !important;
  }
  .top_banner .rightTxt p:last-child {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 700px) {
  .top_banner .leftTxt {
    margin-right: 15px !important;
  }
}
@media screen and (max-width: 638px) {
  .top_banner {
    padding: 0 10px;
  }
  .top_banner .leftTxt {
    display: none;
  }
  .top_banner .leftTxt_m {
    display: block !important;
    position: relative;
    font-size: 12px;
    text-align: center;
    color: #fff900;
    padding-right: 10px;
    border-radius: 25px;
    font-weight: 800;
    margin-right: 0px;
  }
}
</style>