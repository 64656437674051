import { loginUser } from "@/api/user";
import VueCookies from "vue-cookies";

const userStore = {
  namespaced: true,
  state: {
    userId: VueCookies.get("userId") || "",
    userNm: VueCookies.get("userNm") || "",
    userGrade: VueCookies.get("userGrade") || "",
    loginDate: VueCookies.get("loginDate") || "",
    accessToken: VueCookies.get("accessToken") || "",
    refreshToken: VueCookies.get("refreshToken") || "",
    expireDate: VueCookies.get("expireDate") || "",
    sessionTime: 0,
    isLogoutFlag: false,
    isLogoutGuideFlag: false,
  },
  getters: {
    isLogin(state) {
      return state.accessToken !== "";
    },
    getId(state) {
      return state.userId;
    },
    getUserNm(state) {
      return state.userNm;
    },
    getUserGrade(state) {
      return state.userGrade;
    },
    getUserLoginDate(state) {
      return state.loginDate;
    },
    getAccessToken(state) {
      return state.accessToken;
    },
    getRefreshToken(state) {
      return state.refreshToken;
    },
    getExpireDate(state) {
      return state.expireDate;
    },
    getTime(state) {
      return state.sessionTime;
    },
    getIsLogoutShow(state) {
      return state.isLogoutFlag;
    },
    getIsLogoutGuideShow(state) {
      return state.isLogoutGuideFlag;
    },
    getSessionTime(state) {
      var restSec = state.sessionTime;
      var nMin = 0;
      var nSec = 0;
      if (restSec > 0) {
        nMin = parseInt(restSec / 60);
        nSec = restSec % 60;
        if (nMin > 60) {
          nHour = parseInt(nMin / 60);
          nMin = nMin % 60;
        }
      }
      if (nSec < 10) nSec = "0" + nSec;
      if (nMin < 10) nMin = "0" + nMin;
      return nMin + "분 " + nSec + "초";
    },

    getSessionTime2(state) {
      var restSec = state.sessionTime;
      var nMin = 0;
      var nSec = 0;
      if (restSec > 0) {
        nMin = parseInt(restSec / 60);
        nSec = restSec % 60;
        if (nMin > 60) {
          nHour = parseInt(nMin / 60);
          nMin = nMin % 60;
        }
      }
      if (nSec < 10) nSec = "0" + nSec;
      if (nMin < 10) nMin = "0" + nMin;
      return nMin + ":" + nSec;
    },
    getOneHourLater(state) {
      return state.oneHourLater;
    },
    getUserAuthorityCode(state) {
      if (state.userGrade === "PLATINUM") {
        return 1;
      }
      if (state.userGrade === "BASIC") {
        return 2;
      }
      if (state.userGrade === "FREE" || state.userGrade === "GUEST") {
        return 3;
      }
    },
  },
  mutations: {
    SET_ID(state, userId) {
      state.userId = userId;
    },
    SET_USER_NM(state, userNm) {
      state.userNm = userNm;
    },
    SET_USER_GRADE(state, userGrade) {
      state.userGrade = userGrade;
    },
    SET_LOGIN_DATE(state, loginDate) {
      state.loginDate = loginDate;
    },
    SET_ACCESS_TOKEN(state, accessToken) {
      state.accessToken = accessToken;
    },
    SET_REFRESH_TOKEN(state, refreshToken) {
      state.refreshToken = refreshToken;
    },
    CLEAR_ID(state) {
      state.userId = "";
    },
    CLEAR_ATHORITY(state) {
      state.userGrade = "";
    },
    CLEAR_ACCESS_TOKEN(state) {
      state.accessToken = "";
    },
    CLEAR_LOGIN_DATE(state) {
      state.loginDate = "";
    },
    CLEAR_REFRESH_TOKEN(state) {
      state.refreshToken = "";
    },
    CLEAR_ALL(state) {
      state.userId = "";
      state.userGrade = "";
      state.accessToken = "";
      state.loginDate = "";
      state.refreshToken = "";
      state.sessionTime = 0;
    },
    SET_EXPIRE_DATE(state, expireDate) {
      state.expireDate = expireDate;
    },
    SET_SESSION_TIME(state, sessionTime) {
      state.sessionTime = sessionTime
    },
    DISCREMENT_SESSION_TIME(state) {
      state.sessionTime--;
    },
    SET_LOGOUT_SHOW_TRUE(state) {
      state.isLogoutFlag = true;
    },
    SET_LOGOUT_SHOW_FALSE(state) {
      state.isLogoutFlag = false;
    },
    SET_LOGOUT_GUIDE_SHOW_TRUE(state) {
      state.isLogoutGuideFlag = true;
    },
    SET_LOGOUT_GUIDE_SHOW_FALSE(state) {
      state.isLogoutGuideFlag = false;
    },
  },
  actions: {
    async LOGIN({ commit, dispatch }, loginUserData) {
      const { data } = await loginUser(loginUserData);
      commit("SET_ID", data.data.userId);
      commit("SET_USER_NM", data.data.userNm);
      commit("SET_USER_GRADE", data.data.userGrade);
      commit("SET_LOGIN_DATE", data.data.loginDtim);
      commit("SET_ACCESS_TOKEN", data.data.accessToken);
      VueCookies.set("userId", data.data.userId);
      VueCookies.set("userNm", data.data.userNm);
      VueCookies.set("userGrade", data.data.userGrade);
      VueCookies.set("loginDate", data.data.loginDtim);
      VueCookies.set("accessToken", data.data.accessToken);
      dispatch("CALC_EXPIRE_DATE");
      dispatch("START_SESSION_TIMER");
    },

    LOGOUT({ commit }) {
      commit("CLEAR_ALL");
      VueCookies.remove("userId");
      VueCookies.remove("userNm");
      VueCookies.remove("userGrade");
      VueCookies.remove("accessToken");
      VueCookies.remove("loginDate");
      VueCookies.remove("expireDate");
      VueCookies.remove("refreshToken");
      VueCookies.remove("findUserNm");
      VueCookies.remove("findUserId");
    },

    async REFRESH_TOKEN({ commit }, data) {
      commit("SET_ACCESS_TOKEN", data.data.accessToken);
      VueCookies.set("userGrade", data.data.userGrade);
      VueCookies.set("accessToken", data.data.accessToken);
    },

    START_SESSION_TIMER({ commit, state, dispatch }) {
      if (state.accessToken) {
        dispatch("GET_TIME_DIFF");
        const intervalId = setInterval(() => {
          if (state.sessionTime % 10 == 0) {
            dispatch("GET_TIME_DIFF");
          }
          commit("DISCREMENT_SESSION_TIME");
          if (!state.expireDate) {
            dispatch("LOGOUT");
          }

          if (state.sessionTime < 1) {
            clearInterval(intervalId);
          }
        }, 1000);
      }
    },

    LOGIN_EXTENSION({ commit, dispatch }, accessToken) {
      commit("SET_ACCESS_TOKEN", accessToken);
      VueCookies.set("accessToken", accessToken);
      commit("SET_LOGOUT_SHOW_FALSE");
      commit("SET_LOGOUT_GUIDE_SHOW_FALSE");
      dispatch("CALC_EXPIRE_DATE");
      dispatch("GET_TIME_DIFF");
    },

    CALC_EXPIRE_DATE({ commit }) {
      var today = new Date();
      var expireDate = new Date(today.getTime() + 1000 * 60 * 30);
      today = today.getFullYear().toString().padStart(4, '0') +
        (today.getMonth() + 1).toString().padStart(2, '0') +
        today.getDate().toString().padStart(2, '0') +
        today.getHours().toString().padStart(2, '0') +
        today.getMinutes().toString().padStart(2, '0') +
        today.getSeconds().toString().padStart(2, '0');

      expireDate = expireDate.getFullYear().toString().padStart(4, '0') +
        (expireDate.getMonth() + 1).toString().padStart(2, '0') +
        expireDate.getDate().toString().padStart(2, '0') +
        expireDate.getHours().toString().padStart(2, '0') +
        expireDate.getMinutes().toString().padStart(2, '0') +
        expireDate.getSeconds().toString().padStart(2, '0');
      VueCookies.set("expireDate", expireDate);
      commit("SET_EXPIRE_DATE", expireDate);
    },

    GET_TIME_DIFF({ commit, state }) {
      var expire = state.expireDate;
      var now = new Date();
      now =
        now.getFullYear().toString().padStart(4, "0") +
        now.getMonth().toString().padStart(2, "0") +
        now.getDate().toString().padStart(2, "0") +
        now.getHours().toString().padStart(2, "0") +
        now.getMinutes().toString().padStart(2, "0") +
        now.getSeconds().toString().padStart(2, "0");
      now = new Date(
        parseInt(now.substring(0, 4)),
        parseInt(now.substring(4, 6)),
        parseInt(now.substring(6, 8)),
        parseInt(now.substring(8, 10)),
        parseInt(now.substring(10, 12)),
        parseInt(now.substring(12, 14))
      );
      expire = new Date(
        parseInt(expire.substring(0, 4)),
        parseInt(expire.substring(4, 6)) - 1,
        parseInt(expire.substring(6, 8)),
        parseInt(expire.substring(8, 10)),
        parseInt(expire.substring(10, 12)),
        parseInt(expire.substring(12, 14))
      );
      var timeDiff = expire - now;
      var secondsDiff = timeDiff / 1000;
      commit("SET_SESSION_TIME", secondsDiff);
    },

    LOGOUT_SHOW_TRUE({ commit }) {
      commit("SET_LOGOUT_SHOW_TRUE");
    },
    LOGOUT_SHOW_FALSE({ commit }) {
      commit("SET_LOGOUT_SHOW_FALSE");
    },
    LOGOUT_GUIDE_SHOW_TRUE({ commit }) {
      commit("SET_LOGOUT_GUIDE_SHOW_TRUE");
    },
    LOGOUT_GUIDE_SHOW_FALSE({ commit }) {
      commit("SET_LOGOUT_GUIDE_SHOW_FALSE");
    },

  },
};

export default userStore;
