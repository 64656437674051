import { instanceWithAuth } from "@/api/index";

function getContactList(searchData) {
    return instanceWithAuth.get("/customers/contact-history", { params: searchData });
}
function getMailInfo(param) {
    return instanceWithAuth.get("/customers/mail-info", { params: param });
}

export { getContactList, getMailInfo };
