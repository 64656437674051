<template>
  <div>
    <div class="wrap" id="wrap">
      <div class="section-cont">
        <button
          type="button"
          class="btn-sec btn--small"
          data-roll="modal-popup"
          data-popid="modalPop01"
        >
          로그아웃 알림
        </button>
      </div>
    </div>
    <!-- //wrap -->

    <!-- [팝업]로그아웃 알림 -->
    <div
      class="modal-popup modal-popup--small"
      id="modalPop01"
      style="z-index: 1000"
    >
      <div class="modal-popup__wrap">
        <div class="modal-popup__head">
          <h1 class="modal-popup__title">로그아웃 알림</h1>
        </div>
        <div class="modal-popup__body">
          <div class="notice-msg">
            <p class="notice-msg__title">{{ getSessionTime2 }}</p>
            <p class="notice-msg__text">
              <strong>자동 로그아웃 예정입니다.</strong>
            </p>
            <p class="notice-msg__desc">
              고객님의 안전한 이용을 위하여 30분 마다 <br />
              <strong class="txt-point01">로그인 연장</strong>이 필요합니다.
            </p>
          </div>

          <div class="btn-wrap">
            <button class="btn-pri btn--large" @click="loginExtensions">
              로그인 연장
            </button>
          </div>
        </div>
        <!-- //modal-popup__body -->
        <div class="modal-popup__close" @click="popupClose">
          <button type="button"><span class="blind">팝업닫기</span></button>
        </div>
      </div>
    </div>
    <!-- //[팝업]로그아웃 알림 -->
    <ModalAlert ref="ModalAlert" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ModalAlert from "@/components/common/ModalAlert.vue";
import { loginExtension } from "@/api/user";
export default {
  components: { ModalAlert },
  props: {
    isLogoutShow: Boolean,
  },
  data: () => ({}),
  computed: {
    ...mapGetters("userStore", ["getSessionTime2"]),
    ...mapGetters("userStore", ["isLogin"]),
  },

  mounted() {
    this.modalPopupOpen("modalPop01");
  },

  methods: {
    //레어어팝업 열기
    modalPopupOpen(popUpID) {
      let openPopup = document.getElementById(popUpID);
      openPopup.classList.add("modal-popup--show");
      openPopup.setAttribute("tabindex", "0");
      openPopup.focus();
      // document.body.style.overflow = "hidden";
    },
    popupClose() {
      this.modalPopupClose("modalPop01");
      this.$emit("isLogoutShow", false);
    },
    //레이어팝업 닫기
    modalPopupClose(popUpID) {
      let closePopup = document.getElementById(popUpID);
      closePopup.classList.remove("modal-popup--show");
      closePopup.removeAttribute("tabindex");
      document.body.removeAttribute("style");
    },

    async loginExtensions() {
      try {
        const { data } = await loginExtension();
        await this.$store.dispatch("userStore/LOGIN_EXTENSION", data.data);
      } catch (err) {
        if (
          err.response.data.status === 401 &&
          err.response.data.code === "A003"
        ) {
          return;
        }

        this.callAlert("로그인 연장에 실패하였습니다<br>다시 로그인해주세요.");
        if (this.$route.path !== "/login") {
          this.$router.push("/login");
        }
      } finally {
      }
    },

    callAlert(message) {
      const flag = this.$refs.ModalAlert.show({
        title: "확인",
        content: `${message}`,
      });
    },
  },
};
</script>