import { instanceWithAuth, multipartInstanceWithAuth } from "@/api/index";

function noticeBizCmpNm() {
    return instanceWithAuth.get("/customers/get-user-info");
}

function totalNationCode() {
    return instanceWithAuth.get("/customers/total-nation-cd");
}

function uploadContactForm(param) {
    return multipartInstanceWithAuth.post("/customers/upload-contact-form", param);
}

export { noticeBizCmpNm, totalNationCode, uploadContactForm};
