import Vue from "vue";
import Vuex from "vuex";
import userStore from '@/store/modules/userStore';
import noticeStore from '@/store/modules/noticeStore';
import buyerStore from '@/store/modules/buyerStore';
import contactStore from '@/store/modules/contactStore';
import addressStore from '@/store/modules/addressStore';
import cashStore from '@/store/modules/cashStore';
import bmStore from "@/store/modules/bmStore";
import reportStore from "@/store/modules/reportStore";
import searchStore from "@/store/modules/searchStore";
import commonStore from "@/store/modules/commonStore";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    userStore,
    noticeStore,
    buyerStore,
    contactStore,
    addressStore,
    cashStore,
    bmStore,
    reportStore,
    searchStore,
    commonStore,
  },
});
