import { instanceWithAuth } from "@/api/index";

function postSearchBuyer(data) {
    return instanceWithAuth.post("/search/search-buyer", data);
}
function postSearchReport(data) {
    return instanceWithAuth.post("/search/search-report", data);
}
function postSearchNotice(data) {
    return instanceWithAuth.post("/search/search-notice", data);
}
function postSearchHistory(data) {
    return instanceWithAuth.post("/search/history", data);
}
function postDeleteSearchHistory(data) {
    return instanceWithAuth.post("/search/delete-history", data);
}
function getSearchAutoComplete(param) {
    return instanceWithAuth.get("/search/auto-complete/buyer-name", { params: param });
}
function getBuyerSearch(param) {
    return instanceWithAuth.get("/search/buyer", { params: param });
}
function buyerCategorySearch(param) {
    return instanceWithAuth.get("/search/buyer-category", { params: param });
}
export {
    postSearchBuyer, postSearchReport, postSearchNotice, postSearchHistory,
    postDeleteSearchHistory, getSearchAutoComplete, getBuyerSearch, buyerCategorySearch,
};