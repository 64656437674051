import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueSweetalert2 from 'vue-sweetalert2';
import VueCookies from "vue-cookies";
import swiper from "vue-awesome-swiper";
import VueFullPage from "vue-fullpage.js";
import VueGtag from "vue-gtag";

Vue.config.productionTip = false;
Vue.use(VueSweetalert2);
Vue.use(VueCookies);
Vue.use(swiper);
Vue.use(VueFullPage);
Vue.use(VueGtag,
  {
    config: {
      id: "GTM-TFGHBQXG",
    },
  },
  router);

router.afterEach((to, from) => {
  window.scrollTo(0, 0);
});

Vue.$cookies.config("1d");
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
