<template>
  <Modal ref="baseModal">
    <div class="modal-alert modal-alert--show" id="modalAlert" style="tabindex: 0;">
      <div class="modal-alert__wrap">
        <div class="modal-alert__head">
          <h1 class="modal-alert__title">{{title}}</h1>
        </div>
        <div class="modal-alert__body">
          <p class="modal-alert__msg" v-html="content"></p>
        </div>
        <div class="modal-alert__btnArea">
          <button
            id="modal-alert-btn-confirm"
            ref="confirm"
            type="button"
            class="btn-alert-action"
            @click="confirm"
          >{{confirmButtonText}}</button>
        </div>
      </div>
    </div>
  </Modal>
</template>
<script>
import Modal from "@/components/common/Modal.vue";
export default {
  components: {
    Modal,
  },
  data: () => ({
    title: "확인",
    content: "확인해 주세요.",
    confirmButtonText: "확인",
    resolvePromise: undefined,
  }),
  methods: {
    show(content) {
      this.setContent(content);
      this.$refs.baseModal.open();

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
      });
    },
    confirm() {
      this.$refs.baseModal.close();
      this.resolvePromise(true);
    },
    error(error) {
      let content = "서버와 통신 중 오류가 발생하였습니다.";

      if (error.response != undefined) {
        if (error.response.data != undefined) {
          content = error.response.data.message;
        }
      }

      this.setContent({
        title: "알림",
        content: content,
      });

      this.$refs.baseModal.open();

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
      });
    },
    setContent(content) {
      this.title = content.title ? content.title : this.title;
      this.content = content.content ? content.content : this.content;
      this.confirmButtonText = content.confirmButtonText
        ? content.confirmButtonText
        : this.confirmButtonText;
    },
  },
};
</script>