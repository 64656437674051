import { getCashProduct, getCashUsageList } from "@/api/cash";

const cashStore = {
  namespaced: true,
  state: {
    cashProductList: [],
    cashUsageList: [],
  },
  getters: {
    getCashProduct(state) {
      return state.cashProductList;
    },
    getCashUsageList(state) {
      return state.cashUsageList;
    },
  },
  mutations: {
    SET_CASH_PRODUCT(state, cashProductList) {
      state.cashProductList = cashProductList;
    },
    SET_CASH_USAGE_LIST(state, cashUsageList) {
      state.cashUsageList = cashUsageList;
    },
    CLEAR_CASH_PRODUCT(state) {
      state.cashProductList = [];
    },
  },
  actions: {
    async GET_CASH_PRODUCT({ commit }) {
      const { data } = await getCashProduct();
      commit("SET_CASH_PRODUCT", data.data);
    },
    async GET_CASH_USAGE_LIST({ commit }) {
      const { data } = await getCashUsageList();
      commit("SET_CASH_USAGE_LIST", data.data);
    },
  },
};

export default cashStore ;
