import { instance, instanceWithAuth, multipartInstanceWithAuth } from "@/api/index";

function getValidExvt(checkData) {
    return instanceWithAuth.get("/bm/valid-exvt", { params: checkData });
}

function getSenderInfo() {
    return instanceWithAuth.get("/bm/sender-info");
}

function getConfirmInfo(param) {
    return instanceWithAuth.get("/bm/confirm-info", { params: param });
}

function sendMail(param) {
    return multipartInstanceWithAuth.post("/bm/send", param);
}

function getMktInfo(param) {
    return instanceWithAuth.get("/bm/mkt-info", { params: param });
}

function getSendList(param) {
    return instanceWithAuth.get("/bm/send-history", { params: param });
}

function deleteMktHistory(param) {
    return instanceWithAuth.post("/bm/send-history-delete", param);
}

function getMailInfo(param) {
    return instanceWithAuth.get("/bm/mail-info", { params: param });
}

function getMktSndList(param) {
    return instanceWithAuth.get("/bm/mkt-snd-history", { params: param });
}

function getMktSndCnt(param) {
    return instanceWithAuth.get("/bm/mkt-snd-cnt", { params: param });
}

function getCmpNm(param) {
    return instance.get("/bm/edm/cmp-nm", { params: param });
}

function sendEdm(param) {
    return instance.post("/bm/edm/send", param);
}

function getReplyTitleAndContent(param) {
    return instanceWithAuth.get("/bm/mkt-reply-content", { params: param })
}

function getTmpCnt() {
    return instanceWithAuth.get("/bm/tmp-cnt");
}

function saveTmp(param) {
    return multipartInstanceWithAuth.post("/bm/tmp", param);
}

function getTmpList() {
    return instanceWithAuth.get("/bm/tmp-list");
}

function deletMktTmp(param) {
    return instanceWithAuth.post("/bm/tmp-delete", param);
}

function getMktTmp(param) {
    return instanceWithAuth.get("bm/tmp", { params: param });
}

function exvt(param) {
    return instanceWithAuth.post("/bm/exvt", param);
}

function saveTemplate(param) {
    return instanceWithAuth.post("/bm/template", param);
}

function getMktTemplateList(param) {
    return instanceWithAuth.get("/bm/template-list", { params: param });
}

function deleteMktTmpl(param) {
    return instanceWithAuth.post("/bm/template/delete", param);
}

function getTemplateContent(param) {
    return instanceWithAuth.get("/bm/template", { params: param });
}

function unsubscribe(param) {
    return instanceWithAuth.post("/bm/unsubscribe", param);
}

function putExvtMailCnt(param) {
    return instanceWithAuth.post("/bm/exvt-mail-cnt", param);
}

function getExvtContent(param) {
    return instanceWithAuth.get("/bm/exvt-content", { params: param });
}

function getMktData(param) {
    return instanceWithAuth.get("/bm/mkt", { params: param });
}

export {
    getValidExvt,
    getSenderInfo,
    getConfirmInfo,
    sendMail,
    getMktInfo,
    getSendList,
    deleteMktHistory,
    getMailInfo,
    getMktSndList,
    getMktSndCnt,
    getCmpNm,
    sendEdm,
    getReplyTitleAndContent,
    getTmpCnt,
    saveTmp,
    getTmpList,
    deletMktTmp,
    getMktTmp,
    exvt,
    saveTemplate,
    getMktTemplateList,
    deleteMktTmpl,
    getTemplateContent,
    unsubscribe,
    putExvtMailCnt,
    getExvtContent,
    getMktData,
};