import Vue from "vue";
import VueRouter from "vue-router";
import Swal from "sweetalert2";
import store from "@/store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "HomePage",
    component: () => import("@/views/MainPage.vue"),
  },

  // 검색
  {
    path: "/search",
    component: () => import("@/views/search/SearchPage.vue"),
    meta: { authRequired: true },
  },
  {
    path: "/search-address",
    name: "SearchAdress",
    component: () => import("@/views/search/SearchAdressPage.vue"),
  },

  // 유저
  {
    path: "/login",
    component: () => import("@/views/us/LoginPage.vue"),
  },
  {
    path: "/sign-up",
    component: () => import("@/views/us/SignUpPage.vue"),
  },
  {
    path: "/sign-up/fail",
    component: () => import("@/views/us/SignUpFailPage.vue"),
  },
  {
    path: "/sign-up/complete",
    name: "SignUpComplete",
    props: true,
    component: () => import("@/views/us/SignUpCompletePage.vue"),
  },
  {
    path: "/find-id",
    component: () => import("@/views/us/FindIdPage.vue"),
  },
  {
    path: "/find-pwd",
    component: () => import("@/views/us/FindPwdPage.vue"),
  },
  {
    //마이페이지용 비밀번호변경
    path: "/update-pwd",
    name: "UpdatePwd",
    component: () => import("@/views/us/UpdatePwdPage.vue"),
    meta: { authRequired: true },
  },
  {
    // 메인페이지용 비밀번호변경
    path: "/user/update-pwd",
    name: "UserUpdatePwd",
    component: () => import("@/views/us/UserUpdatePwdPage.vue"),
  },
  {
    path: "/nice-pass/result",
    name: "NicePassResult",
    component: () => import("@/views/us/NicePassResultPage.vue"),
  },

  // 빅데이터 발굴
  {
    path: "/bm",
    component: () => import("@/views/bm/BmPage.vue"),
  },
  {
    path: "/bm/big-data",
    component: () => import("@/views/bm/BigDataPage.vue"),
    meta: { authRequired: true },
  },

  // 마케팅 발송
  {
    path: "/bm/history",
    component: () => import("@/views/bm/BmPushHistoryPage.vue"),
    meta: { authRequired: true },
  },
  {
    path: "/bm/push",
    name: "BmPush",
    component: () => import("@/views/bm/BmPushPage.vue"),
    meta: { authRequired: true },
  },
  {
    path: "/bm/push2",
    name: "BmPush2",
    component: () => import("@/views/bm/BmPushPage2.vue"),
    meta: { authRequired: true },
  },
  {
    name: "BmPushComplete",
    path: "/bm/push-complete/:mktNo",
    component: () => import("@/views/bm/BmSendCompletePage.vue"),
    props: true,
    meta: { authRequired: true },
  },
  {
    path: "/bm/push-view/:mktNo",
    component: () => import("@/views/bm/BmSendMailViewPopup.vue"),
    props: true,
    name: "SendMailView",
    meta: { authRequired: true }
  },
  {
    path: "/bm/edm/:mktSndNo",
    component: () => import("@/views/bm/EdmSendPopup.vue"),
    props: true,
    name: "EdmSend",
  },
  {
    path: "/bm/edm/:mktSndNo/:message",
    component: () => import("@/views/bm/EdmSendPopup.vue"),
    props: true,
    name: "EdmSend",
  },
  {
    path: "/bm/tmp",
    name: "BmTmp",
    component: () => import("@/views/bm/BmTmpPage.vue"),
    meta: { authRequired: true },
  },
  {
    path: "/bm/edm-unsubscribe/:mktSndNo",
    component: () => import("@/views/bm/UnsubscribePage.vue"),
    props: true,
    name: "Unsubscribe",
  },

  // 퀵메세지
  {
    name: "BuyerMessageDetail",
    path: "/message/:dunsNo/:contactSeq",
    component: () => import("@/views/common/BuyerMessagePopup.vue"),
    props: true,
    meta: { authRequired: true }
  },

  // 문의, 공지
  {
    name: "CsNotices",
    path: "/cs-notices",
    component: () => import("@/views/cs/CsNoticePage.vue"),
  },
  {
    path: "/cs-contacts",
    component: () => import("@/views/cs/CsContactPage.vue"),
    meta: { authRequired: true },
  },
  {
    path: "/cs-contact-complete",
    component: () => import("@/views/cs/CsContactCompletePage.vue"),
    meta: { authRequired: true },
  },

  // 마이페이지
  {
    name: "MyPage",
    path: "/mypage",
    name: "MyPage",
    component: () => import("@/views/my/MyPage.vue"),
    meta: { authRequired: true },
  },
  {
    path: "/update-info",
    component: () => import("@/views/my/UpdateInfoPage.vue"),
    meta: { authRequired: true },
  },
  {
    path: "/cash-history",
    component: () => import("@/views/my/CashHistoryPage.vue"),
    meta: { authRequired: true },
  },
  {
    path: "/contact-history",
    component: () => import("@/views/my/ContactHistoryPage.vue"),
    meta: { authRequired: true },
  },

  // 해외보고서
  {
    path: "/apply-reports",
    name: 'applyReports',
    component: () => import("@/views/report/ApplyReportPage.vue"),
    meta: { authRequired: true },
  },
  {
    path: "/apply-report-complete",
    name: 'applyReportComplete',
    component: () => import("@/views/report/ApplyReportCompletePage.vue"),
    meta: { authRequired: true },
  },
  {
    path: "/report-lists",
    component: () => import("@/views/report/ReportListPage.vue"),
    meta: { authRequired: true },
  },

  // 컨설팅
  {
    path: "/apply-consults",
    component: () => import("@/views/consult/ApplyConsultPage.vue"),
    meta: { authRequired: true },
  },
  {
    path: "/apply-consult-complete",
    component: () => import("@/views/consult/ApplyConsultCompletePage.vue"),
    meta: { authRequired: true },
  },
  {
    path: "/consult-lists",
    component: () => import("@/views/consult/ConsultListPage.vue"),
    meta: { authRequired: true },
  },
  {
    name: "consultDetail",
    path: "/consult-detail/:consultNo",
    component: () => import("@/views/consult/ConsultDetailPage.vue"),
    meta: { authRequired: true },
  },

  // 바이어 정보
  {
    path: "/buyer-lists",
    component: () => import("@/views/buyer/BuyerListPage.vue"),
    meta: { authRequired: true },
  },
  {
    name: "buyerDetail",
    path: "/buyer-detail/:dunsNo",
    component: () => import("@/views/buyer/BuyerDetailPage.vue"),
    meta: { managerBasic: true },
  },
  {
    name: "FindScambuyers",
    path: "/find-scambuyers",
    component: () => import("@/views/buyer/FindScamBuyerPage.vue"),
    meta: { authRequired: true },
  },

  // Footer
  {
    //이용약관
    name: "PolicyDetails",
    path: "/policy-details",
    component: () => import("@/views/common/PolicyDetailPage.vue"),
  },
  {
    //신용정보활용체제공시
    name: "CreditUsageNotice",
    path: "/credit-usage-notice",
    component: () => import("@/views/common/CreditUsageNoticePage.vue"),
  },
  {
    //이메일무단수집거부
    name: "EmailNoCollection",
    path: "/email-no-collection",
    component: () => import("@/views/common/EmailNoCollectionPage.vue"),
  },

  // 예외
  {
    path: "/404",
    name: "NotFound",
    component: () => import("@/views/NotFoundPage.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(function (to, from, next) {
  const isLogin = store.getters["userStore/isLogin"];
  const userGrade = store.getters["userStore/getUserAuthorityCode"];
  let alertMessage = "";

  if (to.matched.some((routeInfo) => routeInfo.meta.managerPlatinum)) {
    if (userGrade * -1 < -1 || !isLogin) {
      alertMessage = "PLATINUM 등급 이상\n접근 가능한 페이지입니다.";
      next("/");
    }
  } else {
    next();
  }

  if (to.matched.some((routeInfo) => routeInfo.meta.managerBasic)) {
    if (userGrade * -1 < -2 || !isLogin) {
      alertMessage = "BASIC 등급 이상\n접근 가능한 페이지입니다.";
      next("/");
    }
  } else {
    next();
  }

  if (to.matched.some((routeInfo) => routeInfo.meta.managerFree)) {
    if (userGrade * -1 < -3 || !isLogin) {
      alertMessage = "FREE 등급 이상\n접근 가능한 페이지입니다.";
      next("/");
    }
  } else {
    next();
  }

  if (to.matched.some((routeInfo) => routeInfo.meta.authRequired)) {
    const isLogin = store.getters["userStore/isLogin"];
    if (!isLogin) {
      alertMessage = "로그인 후 이용해 주세요.";
      next("/login");
    }
  } else {
    next();
  }

  if (alertMessage) {
    Swal.fire({
      position: "center",
      html: ` <div class="modal-alert__wrap">
                <div class="modal-alert__head">
                  <h1 class="modal-alert__title">알림</h1>
                </div>
                <div class="modal-alert__body">
                  <p class="modal-alert__msg">${alertMessage}</p>
                </div>
              </div>`,
      showConfirmButton: false,
      timer: 1000,
    });

  }
});


export default router;
