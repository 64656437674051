import { getContactList } from "@/api/contact";
import { noticeBizCmpNm } from "@/api/customer";

const contactStore = {
  namespaced: true,
  state: {
    contactList: [],
    noticeBizCmp: {},
    isLoading: false,
  },
  getters: {
    getContactList(state) {
      return state.contactList;
    },
    getNoticeBizCmp(state) {
      return state.noticeBizCmp;
    },
  },
  mutations: {
    SET_CONTACT_LIST(state, contactList) {
      state.contactList = contactList;
    },
    SET_NOTICE_BIZ_CMP(state, noticeBizCmp) {
      state.noticeBizCmp = noticeBizCmp;
    },

    CLEAR_CONTACT_LIST(state) {
      state.contactList = [];
    },
    ON_LOADING(state) {
      state.isLoading = true;
    },
    OFF_LOADING(state) {
      state.isLoading = false;
    },
  },
  actions: {
    async GET_CONTACT_LIST({ commit }, params) {
      const { data } = await getContactList(params);
      commit("SET_CONTACT_LIST", data.data);
    },

    async GET_NOTICE_BIZ_CMP({ commit }) {
      commit("ON_LOADING");
      const { data } = await noticeBizCmpNm();
      commit("SET_NOTICE_BIZ_CMP", data.data);
      commit("OFF_LOADING");
    },
  },
};

export default contactStore;
