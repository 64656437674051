<template>
  <div id="app" :class="{ h100p: height100Percent }">
    <TopBanner class="pointer" v-if="this.mountBanner" />
    <Header v-if="this.mountHeader" />
    <router-view />
    <Footer v-if="this.mountFooter" />
  </div>
</template>

<style>
@import "./assets/css/default.css";
@import "./assets/css/common.css";
</style>

<script>
import Header from "@/components/common/Header.vue";
import Footer from "@/components/common/Footer.vue";
import TopBanner from "@/components/common/TopBanner.vue";

export default {
  name: "App",
  components: {
    Header,
    Footer,
    TopBanner,
  },
  mounted() {
    this.$store.dispatch("userStore/startSessionTimer");
  },
  computed: {
    mountHeader() {
      return (
        this.$route.name !== "NotFound" &&
        this.$route.name !== "UpdatePwd" &&
        this.$route.name !== "UserUpdatePwd" &&
        this.$route.name !== "SearchAdress" &&
        this.$route.name !== "SendMailView" &&
        this.$route.name !== "EdmSend" &&
        this.$route.name !== "BuyerMessage" &&
        this.$route.name !== "BuyerMessageDetail" &&
        this.$route.name !== "eventPopup" &&
        this.$route.name !== "Unsubscribe"
      );
    },
    mountFooter() {
      return (
        this.$route.name !== "NotFound" &&
        this.$route.name !== "UpdatePwd" &&
        this.$route.name !== "UserUpdatePwd" &&
        this.$route.name !== "SearchAdress" &&
        this.$route.name !== "SendMailView" &&
        this.$route.name !== "EdmSend" &&
        this.$route.name !== "BuyerMessage" &&
        this.$route.name !== "BuyerMessageDetail" &&
        this.$route.name !== "HomePage" &&
        this.$route.name !== "eventPopup" &&
        this.$route.name !== "Unsubscribe"
      );
    },
    mountBanner() {
      return (
        // this.$route.name === "HomePage"
        false
      );
    },
    height100Percent() {
      return this.$route.name === "BuyerMessageDetail";
    },
  },
  mounted() {
    this.contentHeadSticky();
  },
  methods: {
    // content head
    contentHeadSticky() {
      document.addEventListener("scroll", function () {
        let contentHead = document.querySelector(".container");
        if (contentHead == null) {
          return;
        }
        let currentScroll = document.documentElement.scrollTop;
        if (currentScroll > 400) {
          contentHead.classList.add("container--sticky");
        } else {
          contentHead.classList.remove("container--sticky");
        }
      });
    },
  },
};
</script>
