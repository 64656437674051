<template>
  <div v-if="isVisable">
    <slot></slot>
  </div>
</template>

<script>
export default {
  data: () => ({
    isVisable: false,
  }),
  methods: {
    open() {
      this.isVisable = true;
      //document.body.style.overflow = "hidden";
    },
    close() {
      this.isVisable = false;
      document.body.removeAttribute("style");
    },
  },
};
</script>