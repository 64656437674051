import { postBuyerAppList, getAttachment, postReportAppList, postBuyerCmpNmList } from "@/api/report";

const reportStore = {
  namespaced: true,
  state: {
    /*
     * 보고서신청 변수
     */
    buyerAppList: [], // 중간값
    buyerCount: 0,
    buyerLocalItems: [],
    buyerOffset: 0,
    visibleBuyerCount: 0,

    existYn: false,
    /*
     * 보고서 신청내역 변수
     */
    reportAppList: [],// 로컬변수
    appCount: 0,
    appOffset: 0,
    appVisibleCount: 10,
    //필터아이템

  },
  getters: {
    getBuyerAppList(state) {
      return state.buyerAppList;
    },
  },
  mutations: {
    SET_BUYERAPP_LIST(state, buyerAppList) {
      state.buyerAppList = buyerAppList;
    },
    SET_BUYERCOUNT(state, count) {
      state.buyerCount = count;
    },
    SET_EXISTYN(state, yn) {
      state.existYn = yn;
    },
    SET_REPORT_APP_LIST(state, reportAppList) {
      state.reportAppList = reportAppList;
    },
    SET_APPCOUNT(state, count) {
      state.appCount = count;
    },
    DELETE_BUYERLOCAL_ITEM(state, param) {
      state.buyerLocalItems = param;
      state.buyerCount = 0;
    },
    SET_BUYERVISIBLE_COUNT(state, param) {
      state.visibleBuyerCount = param;
    },
    SET_BUYEROFFESET(state, param) {
      state.buyerOffset = param;
    },

    DELETE_APPLOCAL_ITEM(state, param) {
      state.reportAppList = param;
    },
    SET_APPVISIBLE_COUNT(state, param) {
      state.appVisibleCount = param;
    },
    SET_APPOFFESET(state, param) {
      state.appOffset = param;
    },
  },
  actions: {
    async GET_BUYERAPP_LIST({ commit }, params) {
      const { data } = await postBuyerAppList(params);
      commit("SET_BUYERAPP_LIST", data.data.buyerAppList);
      commit("SET_BUYERCOUNT", data.data.count);
    },

    async GET_BUYER_CMP_NM_LIST({ commit }, params) {
      const { data } = await postBuyerCmpNmList(params);
      commit("SET_BUYERAPP_LIST", data.data.buyerAppList);
      commit("SET_BUYERCOUNT", data.data.count);
    },

    async GET_ATTACHMENT({ commit }, params) {
      const { data } = await getAttachment(params);
      commit("SET_EXISTYN", data.data);
    },
    async GET_REPORT_APP_LIST({ commit }, params) {
      const { data } = await postReportAppList(params);
      commit("SET_BUYERAPP_LIST", data.data.buyerAppList);
      commit("SET_APPCOUNT", data.data.count);
    },
    SET_ADD_OFFSET({ commit, state }) {
      commit("SET_BUYEROFFESET", state.buyerOffset + 1);
    },
    SET_ADD_VISIBLE_COUNT({ commit, state }) {
      commit("SET_BUYERVISIBLE_COUNT", state.visibleBuyerCount + 10);
    },
    DELETE_BUYERLOCAL_ITEM({ commit }) {
      commit("DELETE_BUYERLOCAL_ITEM", []);
      commit("SET_BUYEROFFESET", 0);
      commit("SET_BUYERVISIBLE_COUNT", 10);
    },

    SET_ADD_APPOFFSET({ commit, state }) {
      commit("SET_APPOFFESET", state.appOffset + 1);
    },
    SET_ADD_APPVISIBLE_COUNT({ commit, state }) {
      commit("SET_APPVISIBLE_COUNT", state.appVisibleCount + 10);
    },
    DELETE_APPLOCAL_ITEM({ commit }) {
      commit("SET_REPORT_APP_LIST", []);
      commit("SET_APPOFFESET", 0);
      commit("SET_APPVISIBLE_COUNT", 10);
    },
  },
}
export default reportStore;
